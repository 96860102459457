export const GETTING_RFQ_STATUS = 'GETTING_RFQ_STATUS'
export const GOT_RFQ_STATUS = 'GOT_RFQ_STATUS'
export const ERROR_GETTING_RFQ_STATUS = 'ERROR_GETTING_RFQ_STATUS'
export const GETTING_INSTALLATIONS = 'GETTING_INSTALLATIONS'
export const GOT_INSTALLATIONS = 'GOT_INSTALLATIONS'
export const ERROR_GETTING_INSTALLATIONS = 'ERROR_GETTING_INSTALLATIONS'
export const CREATING_INSTALLATION = 'CREATING_INSTALLATION'
export const CREATED_INSTALLATION = 'CREATED_INSTALLATION'
export const ERROR_CREATING_INSTALLATION = 'ERROR_CREATING_INSTALLATION'
export const UPDATING_INSTALLATION = 'UPDATING_INSTALLATION'
export const UPDATED_INSTALLATION = 'UPDATED_INSTALLATION'
export const ERROR_UPDATING_INSTALLATION = 'ERROR_UPDATING_INSTALLATION'
export const DISMISS_INSTALLATION_ERROR = 'DISMISS_INSTALLATION_ERROR'
