import { LOGIN, LOGOUT } from '../constants/auth.constants'

const initialState = {
  token: null,
  initialValues: {
    username: '',
    password: '',
  },
}

const auth = (state = initialState, action) => {
  switch (action.type) {
    case LOGIN: {
      return {
        ...state,
        token: action.payload.token,
      }
    }
    case LOGOUT: {
      return {
        ...state,
        token: null,
      }
    }
    default:
      return state
  }
}

export default auth
