import React, { Component } from 'react'
import { Switch, Route, Redirect } from 'react-router-dom'

import routes from '../routes'

class App extends Component {
  render() {
    return (
      <Switch>
        {routes.map((route, key) => {
          // check if logged in via presence of token
          if (route.to) {
            return <Redirect path={route.path} to={route.to} key={key} />
          } else if (route.needsAuth) {
            return (
              <Route path={route.path} component={route.component} key={key} />
            )
          } else if (!route.needsAuth) {
            return (
              <Route path={route.path} component={route.component} key={key} />
            )
          } else {
            return <Redirect to={'/login'} key={key} />
          }
        })}
      </Switch>
    )
  }
}

export default App
