import React, { Component } from 'react'
import { connect } from 'react-redux'
import { Container, Row, Col } from 'reactstrap'
import logo from 'assets/logo-fullfibre@2x.png'

class Thankyou extends Component {
  static propTypes = {}


  render() {
    return (
      <div>
        <header className="fullfibre-header bg-dark text-light p-3 p-md-3">
          <Container className="">
            <img
              src={logo}
              className="fullfibre-logo"
              alt="fullfibre-logo"
              width="200px"
            />
            <h1 className="pt-2">Thank you</h1>
          </Container>
        </header>
        <main>
          <div className="p-3 p-md-5">
            <Container>
              <Row>
                <Col className="px-3 px-md-5">
                  <h2 className="text-red pb-3">
                    Your order has been scheduled and we will send you an email to confirm.
                  </h2>
                </Col>
              </Row>
            </Container>
          </div>
          <div className="what-to-expect bg-dark text-light p-3 p-md-5">
            <Container>
              <Row>
                <Col>
                  <h2 className="text-red pb-3">What to expect on the day?</h2>

                  <p>
                    Full Fibre’s installer will walk through the install with
                    you, they will identify where best to install your fibre
                    broadband connection to, and the best route for the cabling
                    to run. Once you are happy they will install the fibre,
                    starting from the outside of your property. The external
                    work normally takes between 1 - 2 hours with about 45
                    minutes needed to complete the inside works.
                  </p>

                  <p>
                    Once the install has been completed the installer will test
                    the fibre to make sure it’s running at speed and ask you to
                    sign to confirm that you are happy with the installation.
                  </p>

                  <p>
                    If you haven’t received the router at the point we complete
                    the install, your ISP will post you a router or let you know
                    your connection details. It may take a few days for your new
                    connection to become live.{' '}
                  </p>

                  <h2 className="text-red pb-3">Need Help?</h2>
                  <p>
                    If you have any questions or need any help call your ISP.
                  </p>
                </Col>
              </Row>
            </Container>
          </div>
        </main>
      </div>
    )
  }
}

const mapStateToProps = state => ({})

const mapDispatchToProps = {}

export default connect(mapStateToProps, mapDispatchToProps)(Thankyou)
