import {
  GETTING_CUSTOMER_PUBLIC_INFO_BY_RFQ,
  GOT_CUSTOMER_PUBLIC_INFO_BY_RFQ,
  ERROR_GETTING_CUSTOMER_PUBLIC_INFO_BY_RFQ,
  DISMISS_CUSTOMER_PUBLIC_INFO_BY_RFQ_ERROR,
} from '../constants/customer.constants'

const initialState = {
  gettingCustomerPublicInfoByRfq: false,
  customerPublicInfo: [],
  error: false,
  errorMessage: '',
}

const installation = (state = initialState, action) => {
  switch (action.type) {
    case GETTING_CUSTOMER_PUBLIC_INFO_BY_RFQ:
      return {
        ...state,
        error: false,
        gettingCustomerPublicInfoByRfq: true,
        customerPublicInfo: [],
      }
    case GOT_CUSTOMER_PUBLIC_INFO_BY_RFQ:
      return {
        ...state,
        error: false,
        gettingCustomerPublicInfoByRfq: false,
        customerPublicInfo: action.payload,
      }
    case ERROR_GETTING_CUSTOMER_PUBLIC_INFO_BY_RFQ:
      return {
        ...state,
        gettingCustomerPublicInfoByRfq: false,
        customerPublicInfo: [],
        error: true,
        errorMessage: action.payload,
      }

    case DISMISS_CUSTOMER_PUBLIC_INFO_BY_RFQ_ERROR:
      return {
        ...state,
        error: false,
        errorMessage: '',
      }
    default:
      return state
  }
}

export default installation
