import { instance } from '../axios'

export default {
    async getRfqStatus(rfq) {
        try {
            const res = await instance.get(`/orders/installations/${rfq}/status`);

            return res.data
        } catch (err) {
            if (err.response.data.message) {
                throw err.response.data.message
            }
        }
    },

    async getInstallations(rfq, startDate, endDate) {
        try {
            const res = await instance.get(`/orders/installations/${rfq}/${startDate}/${endDate}`);

            return res.data
        } catch (err) {
            if ((err.response.data.message === 'No installation dates available') || (err.response.data.message.includes("Start date cannot be within"))) {
                console.log(err.response.data.message);
                return [];
            } else {
                throw err.response.data.message
            }
        }
    },

    async createInstallation(rfq, values) {
        try {
            const res = await instance.post(`/orders/installations/${rfq}`, values);

            return res.data
        } catch (err) {
            if (err.response.data.message) {
                throw err.response.data.message
            }
        }
    },

    async updateInstallation(rfq, values) {
        try {
            const res = await instance.put(`/orders/installations/${rfq}`, values);

            return res.data
        } catch (err) {
            if (err.response.data.message) {
                throw err.response.data.message
            }
        }
    },
}
