import React, {Fragment} from 'react'
import ReactDOM from 'react-dom'
import {Router} from 'react-router-dom'
import {createBrowserHistory} from 'history'

import './style/index.scss'
import App from './containers/App'
import * as serviceWorker from './serviceWorker'
import {Provider} from 'react-redux'
import {store, persistor} from 'store/index'
import {PersistGate} from 'redux-persist/integration/react'

const history = createBrowserHistory();

ReactDOM.render(
    <Fragment>
        <Provider store={store}>
            <PersistGate loading={null} persistor={persistor}>
                <Router
                    basename={process.env.REACT_APP_ROUTER_BASENAME}
                    history={history}
                >
                    <App/>
                </Router>
            </PersistGate>
        </Provider>
    </Fragment>,
    document.getElementById('root')
);

process.env.NODE_ENV === 'production'
    ? serviceWorker.register()
    : serviceWorker.unregister();
