import { instance } from '../axios'

export default {
  async getCustomerInfoByRfq(rfq) {
    try {
      const res = await instance.get(`/customer/public-info/by-rfq/${rfq}`)
      return res.data
    } catch (err) {
      if (err.response.data.message) {
        throw err.response.data.message
      }
    }
  },
}
