import authApi from '../api/modules/auth.api'
import { LOGIN, LOGOUT } from '../constants/auth.constants'

export default {
  login() {
    return async dispatch => {
      const payload = await authApi.login()
      return dispatch({
        type: LOGIN,
        payload,
      })
    }
  },
  logout() {
    return dispatch => {
      return dispatch({ type: LOGOUT })
    }
  },
}
