import {applyMiddleware, createStore} from 'redux'
import {persistStore, persistReducer, createMigrate} from 'redux-persist'
import localforage from 'localforage'
import rootReducer from '../reducers'
import thunk from 'redux-thunk'
import promise from 'redux-promise-middleware'
import {composeWithDevTools} from 'redux-devtools-extension'

const migrations = {
    0: state => {
        return {...state}
    },
    1: state => {
        return {...state, installation: {}}
    },
};

const persistConfig = {
    key: 'cibp-fullfibre-co',
    storage: localforage,
    // blacklist: ['form'],
    version: 1,
    migrate: createMigrate(migrations, {debug: false}),
};

const persistedReducer = persistReducer(persistConfig, rootReducer);

let store = createStore(
    persistedReducer,
    composeWithDevTools({
        actionsBlacklist: [
            '@@redux-form/REGISTER_FIELD',
            '@@redux-form/UNREGISTER_FIELD',
        ],
        trace: true,
        traceLimit: 25,
    })(applyMiddleware(thunk, promise))
);

let persistor = persistStore(store);

export {store, persistor}
