import customerApi from 'api/modules/customer.api'
import {
  GETTING_CUSTOMER_PUBLIC_INFO_BY_RFQ,
  GOT_CUSTOMER_PUBLIC_INFO_BY_RFQ,
  ERROR_GETTING_CUSTOMER_PUBLIC_INFO_BY_RFQ,
  DISMISS_CUSTOMER_PUBLIC_INFO_BY_RFQ_ERROR,
} from '../constants/customer.constants'

export default {
  getCustomerInfoByRfq(rfq) {
    return async dispatch => {
      dispatch({
        type: GETTING_CUSTOMER_PUBLIC_INFO_BY_RFQ,
      })
      try {
        const payload = await customerApi.getCustomerInfoByRfq(rfq)
        await dispatch({
          type: GOT_CUSTOMER_PUBLIC_INFO_BY_RFQ,
          payload,
        })
      } catch (err) {
        return dispatch({
          type: ERROR_GETTING_CUSTOMER_PUBLIC_INFO_BY_RFQ,
          payload: err,
        })
      }
    }
  },
  dismissInstallationError() {
    return dispatch => {
      dispatch({
        type: DISMISS_CUSTOMER_PUBLIC_INFO_BY_RFQ_ERROR,
      })
    }
  },
}
