import { combineReducers } from 'redux'
import { reducer as form } from 'redux-form'
import auth from './auth.reducers'
import installation from './installation.reducers'
import customer from './customer.reducers'

const rootReducer = combineReducers({
  auth,
  installation,
  customer,
  form,
})

export default rootReducer
