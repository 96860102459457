import Installation from 'components/Installation'
import Thankyou from 'components/Thankyou'

const indexRoutes = [
  { path: '/installation/thankyou', component: Thankyou },
  { path: '/installation/:rfq', component: Installation },
  { path: '/', to: '/installation/123456' },
]

export default indexRoutes
