import {
    GETTING_INSTALLATIONS,
    GOT_INSTALLATIONS,
    ERROR_GETTING_INSTALLATIONS,
    CREATING_INSTALLATION,
    CREATED_INSTALLATION,
    ERROR_CREATING_INSTALLATION,
    UPDATING_INSTALLATION,
    UPDATED_INSTALLATION,
    ERROR_UPDATING_INSTALLATION,
    DISMISS_INSTALLATION_ERROR,
    GETTING_RFQ_STATUS,
    GOT_RFQ_STATUS,
    ERROR_GETTING_RFQ_STATUS,
} from '../constants/installation.constants'

const initialState = {
    gettingRfqStatus: false,
    gettingInstallations: false,
    creatingInstallation: false,
    updatingInstallation: false,
    rfqStatus: {},
    installations: [],
    rfqError: false,
    error: false,
    errorMessage: '',
};

const installation = (state = initialState, action) => {
    switch (action.type) {
        case GETTING_RFQ_STATUS:
            return {
                ...state,
                rfqError: false,
                gettingRfqStatus: true,
                rfqStatus: initialState.rfqStatus,
            };

        case GOT_RFQ_STATUS:
            return {
                ...state,
                rfqError: false,
                gettingRfqStatus: false,
                rfqStatus: action.payload,
            };

        case ERROR_GETTING_RFQ_STATUS:
            return {
                ...state,
                rfqError: true,
                gettingRfqStatus: false,
                errorMessage: action.payload,
            };

        case GETTING_INSTALLATIONS:
            return {
                ...state,
                error: false,
                gettingInstallations: true,
                installations: [],
            };

        case GOT_INSTALLATIONS:
            return {
                ...state,
                error: false,
                gettingInstallations: false,
                installations: action.payload,
            };

        case ERROR_GETTING_INSTALLATIONS:
            return {
                ...state,
                gettingInstallations: false,
                installations: [],
                error: true,
                errorMessage: action.payload,
            };

        case CREATING_INSTALLATION:
            return {
                ...state,
                error: false,
                creatingInstallation: true,
            };

        case CREATED_INSTALLATION:
            return {
                ...state,
                error: false,
                creatingInstallation: false,
            };

        case ERROR_CREATING_INSTALLATION:
            return {
                ...state,
                creatingInstallation: false,
                error: true,
                errorMessage: action.payload,
            };

        case UPDATING_INSTALLATION:
            return {
                ...state,
                error: false,
                updatingInstallation: true,
            };

        case UPDATED_INSTALLATION:
            return {
                ...state,
                error: false,
                updatingInstallation: false,
            };

        case ERROR_UPDATING_INSTALLATION:
            return {
                ...state,
                updatingInstallation: false,
                error: true,
                errorMessage: action.payload,
            };

        case DISMISS_INSTALLATION_ERROR:
            return {
                ...state,
                error: false,
                errorMessage: '',
            };

        default:
            return state
    }
};

export default installation
