import axios from 'axios'
import { request, response } from './interceptors'

const instance = axios.create({
  baseURL: process.env.REACT_APP_API_BASE_URI,
  timeout: 10000,
})

const convertInstance = axios.create({
  baseURL: process.env.REACT_APP_CONVERT_BASE_URI,
  timeout: 300000,
})

instance.interceptors.request.use(request.success, request.failure)

instance.interceptors.response.use(response.success, response.failure)

export { instance, convertInstance }
