import axios from 'axios'

const instance = axios.create({
    baseURL: process.env.REACT_APP_API_BASE_URI,
    timeout: 5000,
});

export default {
    async login() {
        try {
            const res = await instance.post('/authorisation/login/', {
                username: process.env.REACT_APP_FULLFIBRE_USERNAME,
                password: process.env.REACT_APP_FULLFIBRE_PASS,
            });
            return res.data
        } catch (err) {
            if (err.response.data) {
                throw err.response.data
            }
        }
    },

    async logout() {
        try {
            await instance.get('/authorisation/logout')
        } catch (err) {
            throw err.response.data
        }
    },
}
