import installationApi from 'api/modules/installation.api'
import {
    GETTING_RFQ_STATUS,
    GOT_RFQ_STATUS,
    ERROR_GETTING_RFQ_STATUS,
    GETTING_INSTALLATIONS,
    GOT_INSTALLATIONS,
    ERROR_GETTING_INSTALLATIONS,
    CREATED_INSTALLATION,
    CREATING_INSTALLATION,
    ERROR_CREATING_INSTALLATION,
    UPDATING_INSTALLATION,
    UPDATED_INSTALLATION,
    ERROR_UPDATING_INSTALLATION,
    DISMISS_INSTALLATION_ERROR,
} from 'constants/installation.constants'

export default {
    getRfqStatus(rfq) {
        return async dispatch => {
            dispatch({
                type: GETTING_RFQ_STATUS,
            });

            try {
                const payload = await installationApi.getRfqStatus(rfq);
                await dispatch({
                    type: GOT_RFQ_STATUS,
                    payload: payload,
                })
            } catch (err) {
                return dispatch({
                    type: ERROR_GETTING_RFQ_STATUS,
                    payload: err,
                })
            }
        }
    },

    getInstallations(rfq, startDate, endDate) {
        return async dispatch => {
            dispatch({
                type: GETTING_INSTALLATIONS,
            });

            try {
                const payload = await installationApi.getInstallations(
                    rfq,
                    startDate,
                    endDate
                );

                await dispatch({
                    type: GOT_INSTALLATIONS,
                    payload,
                })
            } catch (err) {
                return dispatch({
                    type: ERROR_GETTING_INSTALLATIONS,
                    payload: err,
                })
            }
        }
    },

    createInstallation(rfq, values) {
        return async dispatch => {
            dispatch({
                type: CREATING_INSTALLATION,
            });

            try {
                const payload = await installationApi.createInstallation(rfq, values);

                return dispatch({
                    type: CREATED_INSTALLATION,
                    payload,
                })
            } catch (err) {
                dispatch({
                    type: ERROR_CREATING_INSTALLATION,
                    payload: err,
                })
            }
        }
    },

    updateInstallation(rfq, values) {
        return async dispatch => {
            dispatch({
                type: UPDATING_INSTALLATION,
            });

            try {
                const payload = await installationApi.updateInstallation(rfq, values);

                return dispatch({
                    type: UPDATED_INSTALLATION,
                    payload,
                })
            } catch (err) {
                return dispatch({
                    type: ERROR_UPDATING_INSTALLATION,
                    payload: err,
                })
            }
        }
    },

    dismissInstallationError() {
        return dispatch => {
            dispatch({
                type: DISMISS_INSTALLATION_ERROR,
            })
        }
    },
}
