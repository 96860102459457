import { store } from '../store'
import { LOGOUT } from '../constants/auth.constants'

// Add a request interceptor
export const request = {
  success: config => {
    const state = store.getState()
    if (!state.auth.token) {
      console.error('no token in localstorage') // eslint-disable-line
    } else {
      config.headers['X-API-KEY'] = state.auth.token
    }
    return config
  },
  // Do something with request error
  failure: error => Promise.reject(error),
}

// Add a response interceptor
export const response = {
  success: res => res,
  failure: error => {
    if (error.response && error.response.status === 401) {
      store.dispatch({ type: LOGOUT })
    }
    return Promise.reject(error)
  },
}
