import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'

import {
    reduxForm,
    formValueSelector,
    propTypes as reduxFormPropTypes,
    Field,
    SubmissionError,
} from 'redux-form'

import {
    Container,
    Row,
    Col,
    Button,
    Toast,
    ToastHeader,
    ToastBody,
    Alert,
} from 'reactstrap'

import moment from 'moment'
import cx from 'classnames'
import logo from 'assets/logo-fullfibre@2x.png'
import authActions from 'actions/auth.actions'
import installationActions from 'actions/installation.actions'
import customerActions from 'actions/customer.actions'
import packageJson from '../../package.json'

Object.size = function(obj) {
    let size = 0,
        key;
    for (key in obj) {
        // noinspection JSUnfilteredForInLoop
        if (obj[key].length !== 0) size++;
    }
    return size;
};

let d = new Date();
let day = d.getDay();

const form = 'install-date';

const selector = formValueSelector(form);

const onSubmit = async (values, dispatch, props) => {
    const rfq = props.match.params.rfq;

    if (rfq) {
        const installation = props.installations.find(
            i => i.uid === values.installation
        );

        if (installation) {
            try {
                if (
                    props.rfqStatus &&
                    props.rfqStatus.previous_booking &&
                    props.rfqStatus.can_rebook
                ) {
                    // rebooking
                    await props.updateInstallation(rfq, {
                        installation_date: installation.date,
                        timeslot_id: installation.uid,
                    })
                } else {
                    // new booking
                    await props.createInstallation(rfq, {
                        installation_date: installation.date,
                        timeslot_id: installation.uid,
                    })
                }

                props.history.push('/installation/thankyou')
            } catch (err) {
                console.error(err);

                throw new SubmissionError({
                    _error: `Something went wrong: ${err.message}`,
                })
            }
        } else {
            throw new SubmissionError({
                _error: 'Could not find that installation',
            })
        }
    } else {
        throw new SubmissionError({
            _error: 'Please supply an rfq',
        })
    }
};

const validate = values => {
    const errors = {};

    if (!values.installation) {
        errors.installation = 'You must select an installation'
    }
    return errors
};

class Installation extends Component {
    static propTypes = {
        ...reduxFormPropTypes,
        match: PropTypes.object.isRequired,
    };

    state = {
        startDay: new Date(),
        initialDate: new Date(),
        initialDateConfirmed: false,
        getDataError: false,
        loggedIn: false,
        selectedDate: null,
        downloadingData: false,
        width: window.innerWidth,
    };

    componentWillMount() {
        window.addEventListener('resize', this.handleWindowSizeChange);
    }

    componentWillUnmount() {
        window.removeEventListener('resize', this.handleWindowSizeChange);
    }

    // noinspection JSCheckFunctionSignatures
    async componentDidMount() {
        await this.props.login()
            .then(async () => {
                this.state.loggedIn = true;

                await this._getInstallations();
                await this._getCustomerInfoByRfq();
                await this._getRfqStatus();
            })
            .finally(() => {
                this.render();
            });
    }

    _getInstallations = async () => {
        const rfq = this.props.match.params.rfq;

        // Escape if not logged in
        if (!this.state.loggedIn) {
            return;
        }

        // Check if startDay larger than 3 months from today, if so don't check for dates
        if (this.state.startDay > moment(new Date()).add(3, 'M')) {
            this.setState({getDataError: true});
        }

        let current_date = this.state.startDay.getDay() + 1;

        if (rfq) {
            let startDate = moment(this.state.startDay)
                .add(1, 'd')
                .format('YYYY-MM-DD');
            let endDate = moment(this.state.startDay)
                .add(7, 'd')
                .format('YYYY-MM-DD');

            // get the installations from the database
            await this.props.getInstallations(rfq, startDate, endDate);

            this.setState({downloadingData: false});
        }
    };

    _getCustomerInfoByRfq = async () => {
        const rfq = this.props.match.params.rfq;
        if (rfq) {
            await this.props.getCustomerInfoByRfq(rfq)
        }
    };

    _getRfqStatus = async () => {
        const rfq = this.props.match.params.rfq;

        if (rfq) {
            // get the rfq's status
            await this.props.getRfqStatus(rfq)
        }
    };

    canBook() {
        return (
            this.props.rfqStatus &&
            (!this.props.rfqStatus.previous_booking ||
                (this.props.rfqStatus.previous_booking &&
                    this.props.rfqStatus.can_rebook))
        )
    }

    handleSelectInstallation = installation => {
        this.props.change('installation', installation.uid);
        this.setState({selectedDate: installation.date});
    };

    hasNextWeek = () => {
        // If startDay + 7 days is larger than the current date + 3 months, return false
        return moment(this.state.startDay).add(7, 'd') < moment(new Date()).add(3, 'M');
    };

    hasPreviousWeek = () => {
        // Using .valueOf() to match the fix for pass by reference issues in handleGetNextWeek()
        return this.state.initialDate < this.state.startDay.valueOf();
    };

    disableSubmit = () => {
        try {
            return ((this.state.selectedDate === this.props.rfqStatus.previous_booking.date) && (this.props.installationValue === this.props.rfqStatus.previous_booking.uid));
        } catch (err) {
            return true;
        }
    };

    handleGetNextWeek = () => {
        if (!this.state.downloadingData) {
            this.setState({downloadingData: true});

            if (!this.state.getDataError && this.state.loggedIn) {
                this.setState(
                    {
                        startDate: this.state.startDay.setDate(
                            this.state.startDay.getDate() + 7 - this.state.startDay.getDay()
                        ),
                    },
                    async () => {
                        await this._getInstallations()
                    }
                );
            }

            if (!this.state.initialDateConfirmed) {
                // Use .valueOf() to handle pass by reference issues
                this.setState({initialDate: this.state.startDay.valueOf()});
            }
        }
    };

    handleGetPreviousWeek = () => {
        this.setState(
            {
                startDate: this.state.startDay.setDate(
                    this.state.startDay.getDate() - 7
                ),
            },
            async () => {
                await this._getInstallations()
            }
        )
    };

    handleWindowSizeChange = () => {
        this.setState({ width: window.innerWidth });
    };

    render() {
        const {
            customerPublicInfo,
            rfqStatus,
            installationValue,
            installationsByDay,
            gettingInstallations,
            installationsError,
            dismissInstallationError,
            errorMessage,
            submitFailed,
            error: formError,
            reset,
            invalid,
            submitting,
            handleSubmit,
        } = this.props;

        const isMobile = this.state.width <= 500;
        console.log("Mobile: " + isMobile);

        let renderLoading = false;
        let previous_uid = null;
        let previous_date = null;

        if (gettingInstallations || !this.state.loggedIn) {
            renderLoading = true;
        }

        day = this.state.startDay.getDay();

        let weekBeginning = moment(this.state.startDay).subtract(day - 1, 'd');
        let weekEnd = moment(weekBeginning).add(7, 'd');

        try {
            previous_uid = rfqStatus.previous_booking.uid;
            previous_date = rfqStatus.previous_booking.date;

            if (rfqStatus) {
                // If the previous booking was for the same week as is being displayed
                if (weekBeginning < new Date(rfqStatus.previous_booking.date) && new Date(rfqStatus.previous_booking.date) < weekEnd) {
                    installationsByDay.map((day, i) => {
                        if (rfqStatus.previous_booking.day_of_week - 1 === i) {
                            let found = false;

                            // Loop through installs on this day to see if this one is already present (sometimes occurs when page re-renders)
                            day.map((inst) => {
                                if (inst.uid === rfqStatus.previous_booking.uid) {
                                    found = true;
                                }
                            });

                            if (!found) {
                                day.push(rfqStatus.previous_booking);
                            }

                            previous_uid = rfqStatus.previous_booking.uid;
                        }

                        // Re-order the list so earlier slots are always displayed above (in cases where an earlier install was previously booked)
                        day.sort((a, b) => (a.uid > b.uid) ? 1 : -1);
                    });
                }
            }
        } catch (err) {
            console.log(err.message)
        }

        // noinspection CommaExpressionJS
        return (
            <div>
                <form onSubmit={handleSubmit}>
                    <header className={ isMobile ? "fullfibre-header bg-dark text-light p-3 p-md-3 text-center" : "fullfibre-header bg-dark text-light p-3 p-md-3" }>
                        <Container className="">
                            <img
                                src={logo}
                                className="fullfibre-logo"
                                alt="fullfibre-logo"
                                width="200px"
                            />
                            <h1 className="pt-2">
                                <span className="text-danger">Booking</span> your Installation
                            </h1>
                        </Container>
                    </header>
                    <main>
                        <div className="p-3 p-md-5">
                            <Container>
                                <Row className={isMobile ? "pb-3" : ""}>
                                    <Col md="6" className="px-3 px-md-5 text-center">
                                        {customerPublicInfo && (
                                            <img src={customerPublicInfo.logo_url} alt="isp-logo" />
                                        )}
                                    </Col>
                                    <Col md="6" className={ isMobile ? "px-3 px-md-5 text-center" : "px-3 px-md-5" } style={{
                                        marginTop: "auto",
                                        marginBottom: "auto",
                                    }}>
                                        <p>
                                            Please select the date and time slot below that you would
                                            like us to install your Ultrafast fiberoptic broadband
                                            connection.
                                        </p>
                                    </Col>
                                </Row>
                                {installationsError && (
                                    <Row>
                                        <Col>
                                            <Alert color="warning">{errorMessage}</Alert>
                                        </Col>
                                    </Row>
                                )}
                                {rfqStatus && rfqStatus.previous_booking && (
                                    <Row>
                                        <Col>
                                            <Alert
                                                color={rfqStatus.chargeable_rebook ? 'warning' : 'info'}
                                            >
                                                <>
                                                    You have an existing booking on{' '}
                                                    {moment(rfqStatus.previous_booking.date).format(
                                                        'Do MMMM'
                                                    )}{' '}
                                                    {moment(
                                                        rfqStatus.previous_booking.time_slot_start,
                                                        'HH:mm:ss'
                                                    ).format('ha')}
                                                    {' - '}
                                                    {moment(
                                                        rfqStatus.previous_booking.time_slot_end,
                                                        'HH:mm:ss'
                                                    ).format('ha')}
                                                </>
                                                {rfqStatus.can_rebook && (
                                                    <>
                                                        <br />
                                                        To rebook, please select a new date below
                                                    </>
                                                )}
                                                {rfqStatus.chargeable_rebook && (
                                                    <>
                                                        <br />
                                                        Changing your installation booking less than 72
                                                        hours from your current booking may result in an
                                                        additional charge
                                                    </>
                                                )}
                                            </Alert>
                                        </Col>
                                    </Row>
                                )}
                                {this.canBook() ? (
                                    <>
                                        <Row>
                                            <Col className="d-flex flex-column flex-md-row justify-content-start">
                                                <Button
                                                    color="secondary"
                                                    onClick={() => this.handleGetPreviousWeek()}
                                                    disabled={!this.hasPreviousWeek()}
                                                    className="px-2 mb-3 mr-1"
                                                >
                                                    { isMobile ? "\u2191" : "\u2190"}
                                                </Button>
                                                {!renderLoading ? (
                                                    (Object.size(installationsByDay) === 0 && !this.state.initialDateConfirmed) ? (
                                                        this.handleGetNextWeek()
                                                        //console.log("Failed")
                                                    ) : (
                                                        this.state.initialDateConfirmed = true,
                                                        installationsByDay.map((day, i) => (
                                                            <div
                                                                key={i}
                                                                className={ isMobile ? "flex-fill d-flex flex-grow flex-row flex-md-column" : "flex-fill d-flex flex-row flex-md-column" }
                                                                style={{ minWidth: isMobile ? '50%' : '10%' }}
                                                            >
                                                                {day.length > 0 ? (
                                                                    day.map((installation, j) => (
                                                                        <div
                                                                            key={j}
                                                                            className={cx({
                                                                                'installation-slot shadow-sm border border-gray p-1 mx-1 mx-md-1 mb-3 text-center flex-fill': true,
                                                                                active: ((installation.uid === installationValue) && (installation.date === this.state.selectedDate)),
                                                                                'previous-slot': (
                                                                                    (installation.uid === previous_uid) &&
                                                                                    (installation.date === previous_date) &&
                                                                                    (this.state.selectedDate === null)),
                                                                            })}
                                                                            onClick={() =>
                                                                                this.handleSelectInstallation(
                                                                                    installation
                                                                                )
                                                                            }
                                                                        >
                                                                            <span className="installation-day">
                                                                                {((installation.uid === previous_uid) && (installation.date === previous_date))
                                                                                    ? "RESERVED"
                                                                                    : moment(installation.date)
                                                                                    .format('dddd')
                                                                                    .toUpperCase()}
                                                                            </span>
                                                                            <br />
                                                                            <span className="h3">
                                                                                {moment(installation.date).format('Do')}
                                                                            </span>
                                                                            <br />
                                                                            {moment(installation.date).format('MMMM')}
                                                                            <br />
                                                                            <span className="installation-timeslot">
                                                                                {moment(
                                                                                    installation.time_slot_start,
                                                                                    'HH:mm:ss'
                                                                                ).format('ha')}
                                                                                {' - '}
                                                                                {moment(
                                                                                    installation.time_slot_end,
                                                                                    'HH:mm:ss'
                                                                                ).format('ha')}
                                                                            </span>
                                                                            <Field
                                                                                name="installation"
                                                                                component="input"
                                                                                type="radio"
                                                                                className="d-none"
                                                                                value={installation.uid}
                                                                            />
                                                                        </div>
                                                                    ))
                                                                ) : (
                                                                    isMobile ? (
                                                                            <div
                                                                                className="shadow-sm bg-light border border-gray px-3 mb-3 text-center"
                                                                                style={{
                                                                                    minHeight: '100px',
                                                                                    minWidth: '100%',
                                                                                }}
                                                                            />
                                                                        ) : (
                                                                        <div
                                                                            className="shadow-sm bg-light border border-gray p-1 mx-3 mx-md-3 mb-3 text-center"
                                                                            style={{
                                                                                minHeight: '100px',
                                                                                minWidth: '10%',
                                                                            }}
                                                                        />
                                                                    )
                                                                )}
                                                            </div>
                                                        ))
                                                    )
                                                ) : (
                                                    <div
                                                        className="flex-fill d-flex justify-content-center align-items-center text-center h-100 w-100"
                                                        style={{ minHeight: '200px' }}
                                                    >
                                                        <div className="spinner-border" role="status">
                                                            <span className="sr-only">Loading...</span>
                                                        </div>
                                                    </div>
                                                )}
                                                <Button
                                                    color="secondary"
                                                    onClick={() => this.handleGetNextWeek()}
                                                    disabled={!this.hasNextWeek()}
                                                    className={ isMobile ? "px-2 mb-3" : "px-2 mb-3 ml-1"}
                                                >
                                                    { isMobile ? "\u2193" : "\u2192"}
                                                </Button>
                                            </Col>
                                        </Row>
                                        <Row className="pt-3">
                                            <Col className="text-center">
                                                <Button
                                                    color="primary"
                                                    type="submit"
                                                    disabled={submitting || invalid || submitFailed || this.disableSubmit()}
                                                >
                                                    {!submitting ? (
                                                        'Book Now'
                                                    ) : (
                                                        <div className="spinner-border" role="status">
                                                            <span className="sr-only">Loading...</span>
                                                        </div>
                                                    )}
                                                </Button>
                                            </Col>
                                        </Row>
                                    </>
                                ) : (
                                    <Row>
                                        <Col>
                                            <Alert color="warning">
                                                Sorry you can&apos;t make a booking at the moment
                                            </Alert>
                                        </Col>
                                    </Row>
                                )}
                            </Container>
                        </div>
                        <div className="bg-dark text-light p-3 p-md-5">
                            <Container>
                                <Row>
                                    <Col>
                                        <h2 className="text-red pb-3">
                                            What to expect on the day?
                                        </h2>

                                        <p>
                                            Full Fibre’s installer will walk through the install with
                                            you, they will identify where best to install your fibre
                                            broadband connection to, and the best route for the
                                            cabling to run. Once you are happy they will install the
                                            fibre, starting from the outside of your property. The
                                            external work normally takes between 1 - 2 hours with
                                            about 45 minutes needed to complete the inside works.
                                        </p>

                                        <p>
                                            Once the install has been completed the installer will
                                            test the fibre to make sure it’s running at speed and ask
                                            you to sign to confirm that you are happy with the
                                            installation.
                                        </p>

                                        <p>
                                            If you haven’t received the router at the point we
                                            complete the install, your ISP will post you a router or
                                            let you know your connection details. It may take a few
                                            days for your new connection to become live.{' '}
                                        </p>

                                        <h2 className="text-red pb-3">Need Help?</h2>
                                        <p>
                                            If you have any questions or need any help call your ISP <a href={customerPublicInfo.web_url} target="_blank">{customerPublicInfo.customer_name}</a>.
                                        </p>

                                        <span>
                                            <img
                                                src={logo}
                                                className="fullfibre-logo pt-4"
                                                alt="fullfibre-logo"
                                                width="150px"
                                            />

                                            <p className="small text-muted text-right">
                                                v{packageJson.version}
                                            </p>
                                        </span>
                                    </Col>
                                </Row>
                            </Container>
                        </div>
                    </main>
                </form>
                <div className="fixed-bottom m-3 mx-md-5">
                    <Toast isOpen={installationsError}>
                        <ToastHeader toggle={() => dismissInstallationError()}>
                            Error
                        </ToastHeader>
                        <ToastBody>
                            {String(errorMessage) || 'There was an error'}
                        </ToastBody>
                    </Toast>
                </div>
                <div className="fixed-bottom m-3 mx-md-5">
                    <Toast isOpen={submitFailed}>
                        <ToastHeader toggle={() => reset()}>Error</ToastHeader>
                        <ToastBody>{String(formError) || 'There was an error'}</ToastBody>
                    </Toast>
                </div>
            </div>
        )
    }
}

const mapStateToProps = state => ({
    token: state.auth.token,
    customerPublicInfo: state.customer.customerPublicInfo,
    gettingInstallations: state.installation.gettingInstallations,
    installationValue: selector(state, 'installation'),
    installations: state.installation.installations,
    installationsByDay: state.installation.installations
        ? [null, null, null, null, null, null, null].map((v, i) => {
            let installations = [];
            const { rfqStatus } = state.installation;

            if (rfqStatus && rfqStatus.previous_booking) {
                if (rfqStatus.previous_booking.day_of_week === (day + i + 1) % 7) {
                    installations.push(rfqStatus.previous_booking)
                }
            }

            installations = state.installation.installations.filter(
                inst => inst.day_of_week === (i + 1)
            );

            return installations
        }) : [],

    installationsError: state.installation.error,
    rfqStatus: state.installation.rfqStatus,
    errorMessage: state.installation.errorMessage,
    initialValues: {},
});

const mapDispatchToProps = {
    ...authActions,
    ...installationActions,
    ...customerActions,
};

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(
    reduxForm({
        form,
        validate,
        onSubmit,
    })(Installation)
)
